<template>
  <v-card>
    <v-card-title>

      <v-tooltip right max-width="500" color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
        </template>
        <span>Aqui você acompanha a confecção dos relatórios. Quando o status estiver "Finalizado", você poderá selecionar um ou mais relatórios e baixá-los clicando no botão "Baixar relatórios selecionados"</span>
      </v-tooltip>

    <v-spacer />
    <v-spacer />
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar..." single-line hide-details dense></v-text-field>
  </v-card-title>
  <v-data-table
    :loading="loading"
    loading-text="Carregando informações..."
    v-model="selected"
    show-select
    :headers="headers"
    :items="reports"
    :search="search"
    :sort-by="'created_at'"
    :sort-desc="true"
    :custom-sort="customSort"
    :must-sort="true"
    :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }">
      <template slot="no-data">
          Não há relatórios relacionados às empresas vinculadas ao seu usuário
      </template>

      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox :ripple="false" :value="isSelected && (item.status_process === `BuildSucessful`)"
                           :disabled="!(item.status_process === `BuildSucessful`)" @input="select($event)">
        </v-simple-checkbox>
      </template>

      <template v-slot:item.reportTranslated="{ item }">
        <v-icon left>mdi-file-table-outline</v-icon>
        {{ item.reportTranslated }}
      </template>

      <template v-slot:item.statusMessage="{ item }">
        <v-chip :color="item.statusColor" dark class="blink">
          {{ item.statusMessage }}
        </v-chip>
      </template>

    </v-data-table>

    <div class="download-btn-container">
      <v-btn large color="secondary" class="download-btn" @click="downloadDocumentsInfo"
             :disabled="!(selected.length > 0)" :loading="downloading">
        <v-icon left>mdi-download</v-icon>
        Baixar relatórios selecionados
      </v-btn>
    </div>

    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 white--text error">
          <v-icon left class="white--text">mdi-alert</v-icon>
          Algo deu errado
        </v-card-title>
        <br />
        <v-card-text class="text-h6">
          Tente novamente mais tarde ou entre em contato com o administrador
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text class="secondary lighten-2" @click="errorDialog = false" id='IdBtnClose'> fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import 'moment/locale/pt-br';
import pisCofinsService from '@/services/pisCofinsService.js';
import gedService from '@/services/gedService.js';
import moment from 'moment';

export default {
  name: 'TableStatusRelatoriosPisCofins',

  data() {
    return {
      loading: false,
      search: '',
      error: false,
      errorDialog: false,

      headers: [{
        text: 'Relatório',
        align: 'start',
        sortable: false,
        value: 'reportTranslated',
      },
        {
          text: 'Data de criação',
          value: 'date'
        },
        {
          text: 'Empresa',
          value: 'companyFormatted'
        },
        {
          text: 'Período selecionado',
          sortable: false,
          value: 'competencia'
        },
        {
          text: 'Situação',
          align: 'center',
          value: 'statusMessage'
        },
      ],

      reports: [],

      reportsNameDict: {
        'EFD Contribuicoes Download': 'EFD Contribuições',
        'SPED Fiscal Download': 'SPED Fiscal'
      },

      statusDict: {
        'Request Received': {
          message: 'Aguardando processamento',
          color: "#4d81da" // azul
        },
        'Request recebido': {
          message: 'Aguardando processamento',
          color: "#4d81da" // azul
        },
        BuiltLocally: {
          message: 'Em processamento',
          color: "#4d81da" // azul
        },
        'Building Report': {
          message: 'Em processamento',
          color: "#4d81da" // azul
        },
        BuildSucessful: {
          message: 'Finalizado: Arquivo pronto para download',
          color: "#459f48" // verde escuro
        },
        UploadFailed: {
          message: 'Erro: Falha no upload',
          color: "#de2c1f" // vermelho escuro
        },
        CouldNotBuild: {
          message: 'Erro: Não foi possível gerar o arquivo',
          color: "#de2c1f" // vermelho escuro
        },
      },

      selected: [],

      downloading: false
    };
  },

  mounted() {
    this.getAll();

    let autoUpdateTable10 = window.setInterval(() => this.getAll(), 10000) // 10 segundos
    window.setTimeout(() => clearInterval(autoUpdateTable10), 600000) // 10 minutos
  },

  created() {
    this.init();
  },

  destroyed() {
    clearInterval(this.autoUpdateTable)
  },

  methods: {
    init() {
      this.$on('refresh', this.open);
    },

    open() {
      this.getAll();
    },

    async getAll() {
      try {
        this.loading = true;
        let response = await pisCofinsService.reports_process.getAll();
        this.error = false;
        this.reports = response.data;

        this.reports.map((r) => {
          if (Object.keys(this.reportsNameDict).includes(r.report)) {
            r.reportTranslated = this.reportsNameDict[r.report];
          } else {
            r.reportTranslated = r.report;
          }

          r.competencia = r.start_comp.slice(0, 2) + `/` + r.start_comp.slice(2, 6) + ` — ` + r.end_comp.slice(0, 2) + `/` + r.end_comp.slice(2, 6)

          r.companyFormatted = r.company.replace(/^(\d{2})(\d{3})(\d{3})/, "$1.$2.$3");
          r.date = moment(r.created_at).format('DD/MM/YYYY HH:mm:ss');

          if (Object.keys(this.statusDict).includes(r.status_process)) {
            r.statusMessage = this.statusDict[r.status_process].message;
            r.statusColor = this.statusDict[r.status_process].color;
          } else {
            r.statusMessage = r.status_process;
            r.statusColor = "#afafaf" // cinza
          }
        });
        this.loading = false;
      } catch (error) {
        console.log('error');
        this.error = true;
        this.errorDialog = true;
      }
    },

    async downloadDocumentsInfo() {
      try {
        this.downloading = true
        for (let doc of this.selected) {
          if (doc.status_process === "BuildSucessful") {
            let response = await gedService.files.get(doc.ged_id)
            let fileName = doc.company + ` ` + doc.reportTranslated + ` ` + doc.start_comp + `-` + doc.end_comp + `.zip`
            this.downloadDocument(response, fileName);
          }
        }
        this.downloading = false
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorDialog = true;
        this.downloading = false
      }
    },

    downloadDocument(responseGed, fileName) {
      const linkSource = 'data:application/octet-stream;base64,' + responseGed.data.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='date') {
            if (!isDesc[0]) {
                return new Date(b['created_at']) - new Date(a['created_at']);
            } else {
                return new Date(a['created_at']) - new Date(b['created_at']);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    }
  },
};
</script>

<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.blink {
  -webkit-animation: blink-effect 0.75s linear infinite;
  -moz-animation: blink-effect 0.75s linear infinite;
  -ms-animation: blink-effect 0.75s linear infinite;
  -o-animation: blink-effect 0.75s linear infinite;
  animation: blink-effect 0.75s linear infinite;
}

.download-btn-container {
  display: flex;
  justify-content: center;
}

.download-btn {
  margin: 10px;
}
</style>
