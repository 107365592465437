var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-tooltip",
            {
              attrs: { right: "", "max-width": "500", color: "primary" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                        [_vm._v("mdi-help-circle-outline")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(
                  'Aqui você acompanha a confecção dos relatórios. Quando o status estiver "Finalizado", você poderá selecionar um ou mais relatórios e baixá-los clicando no botão "Baixar relatórios selecionados"'
                ),
              ]),
            ]
          ),
          _c("v-spacer"),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Pesquisar...",
              "single-line": "",
              "hide-details": "",
              dense: "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "v-data-table",
        {
          attrs: {
            loading: _vm.loading,
            "loading-text": "Carregando informações...",
            "show-select": "",
            headers: _vm.headers,
            items: _vm.reports,
            search: _vm.search,
            "sort-by": "created_at",
            "sort-desc": true,
            "custom-sort": _vm.customSort,
            "must-sort": true,
            "footer-props": {
              "items-per-page-options": [10, 25, 50],
            },
          },
          scopedSlots: _vm._u([
            {
              key: "item.data-table-select",
              fn: function ({ item, isSelected, select }) {
                return [
                  _c("v-simple-checkbox", {
                    attrs: {
                      ripple: false,
                      value:
                        isSelected && item.status_process === `BuildSucessful`,
                      disabled: !(item.status_process === `BuildSucessful`),
                    },
                    on: {
                      input: function ($event) {
                        return select($event)
                      },
                    },
                  }),
                ]
              },
            },
            {
              key: "item.reportTranslated",
              fn: function ({ item }) {
                return [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-file-table-outline"),
                  ]),
                  _vm._v(" " + _vm._s(item.reportTranslated) + " "),
                ]
              },
            },
            {
              key: "item.statusMessage",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-chip",
                    {
                      staticClass: "blink",
                      attrs: { color: item.statusColor, dark: "" },
                    },
                    [_vm._v(" " + _vm._s(item.statusMessage) + " ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c("template", { slot: "no-data" }, [
            _vm._v(
              " Não há relatórios relacionados às empresas vinculadas ao seu usuário "
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "download-btn-container" },
        [
          _c(
            "v-btn",
            {
              staticClass: "download-btn",
              attrs: {
                large: "",
                color: "secondary",
                disabled: !(_vm.selected.length > 0),
                loading: _vm.downloading,
              },
              on: { click: _vm.downloadDocumentsInfo },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-download")]),
              _vm._v(" Baixar relatórios selecionados "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.errorDialog,
            callback: function ($$v) {
              _vm.errorDialog = $$v
            },
            expression: "errorDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 white--text error" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "white--text", attrs: { left: "" } },
                    [_vm._v("mdi-alert")]
                  ),
                  _vm._v(" Algo deu errado "),
                ],
                1
              ),
              _c("br"),
              _c("v-card-text", { staticClass: "text-h6" }, [
                _vm._v(
                  " Tente novamente mais tarde ou entre em contato com o administrador "
                ),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary lighten-2",
                      attrs: { text: "", id: "IdBtnClose" },
                      on: {
                        click: function ($event) {
                          _vm.errorDialog = false
                        },
                      },
                    },
                    [_vm._v(" fechar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }